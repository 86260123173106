.regular-button {
    height: 40px;
    text-align: center;
    min-width: 144px;
    border-radius: var(--spacing-3);
    box-shadow: var(--default-shadow);
    background-color: var(--control-background);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
}

    .regular-button.highlighted {
        background-color: var(--active-secondary-background);
        color: var(--secondary-text);
    }

    .regular-button.dangered {
        background-color: var(--error-text);
        color: var(--secondary-text);
    }

    .regular-button:hover {
        background-color: var(--active-background);
        color: var(--secondary-text);
    }

    .regular-button.disabled-button {
        background-color: var(--control-background-disabled);
        color: var(--secondary-text);
        box-shadow: var(--small-shadow);
        cursor: default;
    }
