.ecg-viewer {
    width: 100%;
    min-width: calc(var(--min-width) - 2 * var(--spacing-2));
}

.ecg-viewer-duration {
    font-size: 12px;
    color: var(--primary-text);
}

.ecg-viewer-instruction {
    font-size: 10px;
    color: var(--primary-text);
    opacity: 0.8;
}

.ecg-viewer-button {
    width: 32px;
    height: 32px;
    font-size: 16px;
    border-radius: var(--spacing-2);
    color: var(--control-icon);
    background-color: var(--control-background);
    box-shadow: var(--small-shadow);
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ecg-viewer-button.large-icon {
    font-size: 24px;
}

.ecg-viewer-button:hover {
    cursor: pointer;
    color: var(--control-icon-hover);
}

.ecg-viewer-button.active {
    background-color: var(--active-background);
    color: var(--secondary-text);
}

.ecg-viewer-button.active:hover {
    cursor: pointer;
}

.ecg-viewer-button.disabled {
    opacity: 0.4;
}

.ecg-viewer-button.disabled:hover {
    cursor: default;
}

.ecg-viewer-button span {
    display: none;
    padding: 2px 3px;
    margin-bottom: 64px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ecg-viewer-button:hover span {
    display: inline;
    position: absolute;
    font-size: 10px;
    background: var(--surface-background);
    color: var(--primary-text);
    border: 1px solid var(--control-stroke);
    border-radius: 4px;
}

