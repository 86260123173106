@keyframes logo_heart_beat {
    from {
        transform: scale(1);
    }

    45% {
        transform: scale(1);
        animation-timing-function: ease-in
    }

    50% {
        transform: scale(1.25);
        animation-timing-function: ease-out
    }

    55% {
        transform: scale(1);
    }

    to {
        transform: scale(1);
    }
}

.loader-logo {
    --logo-width: 96px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: calc(-0.5 * var(--logo-width));
    margin-top: calc(-0.5 * var(--logo-width));
    width: var(--logo-width);
    animation: logo_heart_beat 1.5s infinite;
}

.loader-text{
    font-size: 8pt;
    text-align: center;
    width: 300%;
    margin-left: -100%;
    margin-top: 8px;
}
