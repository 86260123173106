.header {
    width: 100%;
    display: flex;
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);
}

.header-options {
    height: 48px;
    display: flex;
    justify-content: space-between;
}

.header-options-divider {
    height: 100%;
    width: 1px;
    background-color: var(--control-stroke);
}

.header-profile {
    display: flex;
    align-items: center;
    padding-left: var(--spacing-1);
    border-top: solid 1px var(--control-stroke);
    border-bottom: solid 1px var(--control-stroke);
    border-left: solid 1px var(--control-stroke);
    border-right: none;
    border-top-left-radius: var(--spacing-3);
    border-bottom-left-radius: var(--spacing-3);
    background: var(--surface-background);
}

.header-profile-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 12px;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-1);
}

.header-profile-link {
    color: var(--primary-text);
    white-space: nowrap;
}

.header-profile-link.signout {
    font-size: 9px;
}

.header-profile-link:hover {
    cursor: pointer;
    color: var(--primary-text-hover);
}

.header-account-dropdown {
    position: relative;
    display: inline-block;
    font-size: 12px;
    padding-left: var(--spacing-2);
}

.header-account-dropdown svg{
    font-size: 1.25rem;
}

.header-account-dropdown .header-profile-link{
    margin-right: var(--spacing-1);
}

.header-account-dropdown-menu {
    display: none;
    position: absolute;
    background-color: var(--control-background);
    min-width: calc(100% - var(--spacing-2));
    box-shadow: var(--default-shadow);
    z-index: 1;
}

.header-account-dropdown-menu .header-profile-link{
    margin: var(--spacing-1) var(--spacing-0);
}

.header-account-dropdown-menu-show {
    display: block;
}

.header-options-dropdown {
    display: flex;
    align-items: center;
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
    border-top: solid 1px var(--control-stroke);
    border-bottom: solid 1px var(--control-stroke);
    border-right: solid 1px var(--control-stroke);
    border-left: solid 1px var(--control-stroke);
    border-radius: var(--spacing-3);
    background: var(--control-background);
}

.header-options-dropdown select {
    background: transparent;
    border: none;
    color: var(--primary-text);
    font-size: 12px;
}

.header-options-dropdown select:hover {
    color: var(--primary-text-hover) !important;
}

.header-options-dropdown select:focus {
    border: none !important;
    outline: none !important;
}

.header-options-dropdown select option {
    background: var(--window-background);
    color: var(--primary-text);
}

.header-options-dropdown select option:hover {
    background-color: var(--active-background);
    color: var(--primary-text);
}

.header-links {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-link {
    color: var(--primary-text);
    white-space: nowrap;
    font-size: 12px;
    text-decoration: none;
    padding-right: var(--spacing-2);
    padding-left: var(--spacing-2);
    height: 40px;
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-link:hover {
    color: var(--primary-text-hover);
    text-decoration: none;
}

.header-link.active {
    color: var(--secondary-text);
    background-color: var(--active-background);
    border-radius: 24px;
    font-weight: 600;
}

.header-avatar-img {
    width: 32px !important;
    height: 32px !important;
}

.header-avatar-img:hover {
    cursor: pointer;
}

.drawer-toggle {
    width: 40px;
    height: 40px;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    border-radius: var(--spacing-0);
    border: solid 1px var(--control-stroke);
    background: transparent;
    color: var(--primary-text);
}

.drawer-toggle:hover {
    cursor: pointer;
    background: var(--active-background);
    color: var(--secondary-text);
}


.drawer-logo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-2);
}

.drawer {
    --drawer-width: 280px;
    z-index: 999;
    padding-top: var(--spacing-4);
    transition: left 1s ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    width: var(--drawer-width);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--drawer-background);
}

.drawer.hidden {
    left: calc(-1 * var(--drawer-width));
}

a.drawer-link {
    color: var(--secondary-text);
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    text-decoration: none;
    padding-right: var(--spacing-2);
    padding-left: var(--spacing-2);
    width: 100%;
    height: 40px;
}

a.drawer-link:hover {
    color: var(--secondary-text-hover);
}

a.drawer-link.active {
    color: var(--secondary-text);
    background-color: var(--active-background);
}

.drawer-user-info {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-1);
}

.drawer-avatar {
    padding-right: var(--spacing-2);
    padding-left: var(--spacing-2);
}

.drawer-avatar-img {
    width: 48px;
    height: 48px;
}

.drawer-avatar-img:hover {
    cursor: pointer;
}

.drawer-profile-links {
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    align-items: flex-end;
    font-size: 12px;
}

.drawer-profile-link {
    color: var(--secondary-text);
    white-space: nowrap;
    padding-right: var(--spacing-2);
    padding-left: var(--spacing-2);
}

.drawer-profile-link.signout {
    font-size: 9px;
}

.drawer-profile-link:hover {
    cursor: pointer;
    color: var(--secondary-text-hover);
}

.drawer-options-dropdown {
    width: 100%;
    display: flex;
    padding-right: var(--spacing-2);
    padding-left: var(--spacing-2);
    align-items: baseline;
}

.drawer-options-dropdown .title {
    color: var(--secondary-text);
    font-size: 16px;
    margin-right: var(--spacing-1);
}

.drawer-options-dropdown select {
    flex-grow: 1;
    background: transparent;
    border: none;
    color: var(--secondary-text);
    font-size: 16px;
}

.drawer-options-dropdown select:hover {
    color: var(--secondary-text-hover) !important;
}

.drawer-options-dropdown select:focus {
    border: none !important;
    outline: none !important;
}

.drawer-options-dropdown select option {
    background: var(--drawer-background);
    color: var(--secondary-text);
}

.drawer-options-dropdown select option:hover {
    background-color: var(--active-background);
    color: var(--secondary-text);
}

.drawer-divider {
    width: 100%;
    height: 1px;
    opacity: 0.3;
    background: var(--secondary-text);
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
}
