.welcome-button {
    width: 32px;
    height: 32px;
    font-size: 16px;
    border-radius: var(--spacing-2);
    color: var(--control-icon);
    background-color: var(--control-background);
    box-shadow: var(--small-shadow);
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-button.large-icon {
    font-size: 24px;
}

.welcome-button:hover {
    cursor: pointer;
    color: var(--control-icon-hover);
}

.welcome-button.active {
    background-color: var(--active-background);
    color: var(--secondary-text);
}

.welcome-button.active:hover {
    cursor: pointer;
}

.welcome-button.disabled {
    opacity: 0.4;
}

.welcome-button.disabled:hover {
    cursor: default;
}

.welcome-button span {
    display: none;
    padding: 2px 3px;
    margin-bottom: 64px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.welcome-button:hover span {
    display: inline;
    position: absolute;
    font-size: 10px;
    background: var(--surface-background);
    color: var(--primary-text);
    border: 1px solid var(--control-stroke);
    border-radius: 4px;
}

.toast-container-welcome{
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.toast-text-welcome{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}