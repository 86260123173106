body {
  --min-width: 480px;
  --min-height: 520px;
  --default-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  --small-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  --spacing-0: 4px;
  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-6: 48px;
  background: var(--window-background);
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: var(--min-width);
  min-height: 100vh;
}

#root, .container-fluid {
  min-height: 100vh;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-fluid {
  display: flex;
  flex-direction: column;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-width: var(--min-width);
  min-height: var(--min-height);
  padding: var(--spacing-4);
  justify-content: center;
  align-items: start;
  background-color: var(--overlay-75);
}

.window-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: var(--min-width);
  min-height: var(--min-height);
  padding: var(--spacing-4);
  justify-content: center;
  align-items: center;
}

.window-modal {
  width: calc(var(--min-width) - 2 * var(--spacing-6));
  background-color: var(--surface-background);
  border-radius: var(--spacing-3);
  box-shadow: var(--default-shadow);
  padding: var(--spacing-4);
}

.window-modal-title {
  color: var(--primary-text);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.window-modal-text {
  color: var(--primary-text);
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
}

.window-modal-error {
  color: var(--error-text);
  font-size: 11px;
  text-align: center;
  white-space: pre-line;
}

.window-modal-button {
  width: 50%;
}

.data-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.data-list {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  border-radius: var(--spacing-0);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  overflow-x: hidden;
  box-shadow: var(--default-shadow);
}

.data-list.empty {
  min-height: 40vh;
}

.data-list .title {
  color: var(--primary-text);
  font-size: 12px;
  font-weight: 600;
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
  white-space: nowrap;
  text-align: center;
  background-color: var(--control-background);
  border-radius: var(--spacing-0);
}

.data-list table {
  width: 100%;
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
}

.data-list tr {
  height: 58px;
  cursor: pointer;
  color: var(--primary-text);
  border-bottom: solid 1px var(--control-stroke);
}

.data-list tr:last-child {
  border-bottom: none;
}

.data-list tr:hover {
  cursor: pointer;
  color: var(--secondary-text);
  background-color: var(--active-background);
}

.data-list tr th {
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  padding: var(--spacing-1) var(--spacing-0);
}

.data-list tr.table-header {
  height: 32px;
  background-color: var(--control-background);
  border-radius: var(--spacing-0);
  border-bottom: none;
}

.data-list tr.table-header:hover {
  background-color: var(--control-background);
  color: var(--primary-text);
  cursor: default;
}

.data-list tr th:first-child {
  padding-left: var(--spacing-2);
}

.data-list tr th:last-child {
  padding-right: var(--spacing-2);
}

.data-list tr td {
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  padding: var(--spacing-2) var(--spacing-0);
}

.data-list tr td:first-child {
  padding-left: var(--spacing-2);
}

.data-list tr td:last-child {
  padding-right: var(--spacing-2);
}

.data-list-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text);
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-1);
}

.data-list-title span {
  font-size: 14px;
  font-weight: 700;
  color: var(--error-text);
}

.placeholder-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-text);
  opacity: 0.6;
}

.details-controls {
  min-width: calc(var(--min-width) - 2 * var(--spacing-2));
  width: calc(var(--min-width));
}

.details-table {
  min-width: calc(var(--min-width) - 2 * var(--spacing-2));
  width: calc(var(--min-width));
  background-color: var(--surface-background);
  border-radius: var(--spacing-0);
  padding: var(--spacing-2);
  box-shadow: var(--default-shadow);
  color: var(--primary-text);
  font-size: 12px;
}

.details-table table {
  width: 100%;
}

.details-table tr {
  border-bottom: solid 1px var(--control-background);
}

.details-table tr:last-child {
  border-bottom: none;
}

.details-table th {
  width: 160px;
  font-weight: 600;
  padding-right: var(--spacing-4);
  padding-top: var(--spacing-0);
  padding-bottom: var(--spacing-0);
}

.details-table td {
  padding-top: var(--spacing-0);
  padding-bottom: var(--spacing-0);
}

.details-table .table-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: var(--spacing-2);
  box-shadow: var(--small-shadow);
  color: var(--primary-text-hover);
  background-color: var(--surface-background);
  font-size: 12px;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
}

.details-table .table-button:hover {
  color: var(--secondary-text);
  background-color: var(--active-background);
  cursor: pointer;
}

select.input-dropdown {
  width: 100%;
  background: transparent;
  border: none;
  color: var(--primary-text);
  font-size: 12px;
  margin-left: -4px;
}

select.input-dropdown:disabled {
  opacity: 1 !important;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.input-dropdown:hover {
  color: var(--primary-text-hover) !important;
}

select.input-dropdown:focus {
  border: none !important;
  outline: none !important;
}

select.input-dropdown option {
  background: var(--window-background);
  color: var(--primary-text-hover);
}

select.input-dropdown option:hover {
  background-color: var(--active-background);
  color: var(--primary-text);
}

div.input-text {
  width: 100%;
  background: transparent;
  border: none;
  color: var(--primary-text);
  font-size: 12px;
  white-space: pre-line;
}

div.input-text.link svg {
  font-size: 1.25rem;
  margin-right: var(--spacing-0);
}

div.input-text:hover {
  cursor: pointer;
  color: var(--primary-text-hover);
}

input.input-text, textarea.input-text {
  width: 100%;
  background: transparent;
  border: none;
  color: var(--primary-text);
  font-weight: 500;
  font-size: 12px;
  font-size-adjust: none;
  padding: 0 !important;
}

input.input-text:focus, textarea.input-text:focus {
  border: none !important;
  outline: none !important;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 12px;
  font-size-adjust: none;
  outline-offset: 0;
}

textarea.input-text {
  resize: none !important;
}

.tab-round-button {
  min-width: 32px;
  height: 32px;
  border-radius: var(--spacing-2);
  box-shadow: var(--small-shadow);
  font-size: 12px;
  color: var(--control-icon);
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
  background-color: var(--control-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-round-button:hover {
  color: var(--control-icon-hover);
  cursor: pointer;
}

.tab-round-button-selected {
  color: var(--secondary-text);
  background-color: var(--active-background);
}

.tab-round-button-selected:hover {
  color: var(--secondary-text);
  cursor: pointer;
}

.context-menu{
  font-size: 12px;
  color: var(--primary-text);
}

.ecg-data-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  border-radius: var(--spacing-0);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  overflow-x: hidden;
  box-shadow: var(--default-shadow);
}

.stethoscope-data-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  border-radius: var(--spacing-0);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  overflow-x: hidden;
  box-shadow: var(--default-shadow);
}

.spiro-data-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--surface-background);
  border-radius: var(--spacing-0);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  overflow-x: hidden;
  box-shadow: var(--default-shadow);
}

.text-tooltip span{
  display: none;
  padding: 2px 3px;
  margin-left: 16px;
  margin-bottom: 64px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-tooltip:hover span{
  display: inline;
  position: absolute;
  font-size: 10px;
  background: var(--surface-background);
  color: var(--primary-text);
  border: 1px solid var(--control-stroke);
  border-radius: 4px;
}

.clickable:hover {
  cursor: pointer;
  color: var(--primary-text-hover);
}